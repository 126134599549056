<template>
  <div v-padding:top="46">
    <van-nav-bar title="排污许可" fixed left-arrow @click-left="goBack">
      <template #right>
        <van-icon name="add-o" color="#1989fa" size="20" @click="addList" />
      </template>
    </van-nav-bar>
    <van-sticky :offset-top="46">
      <div class="search">
        <van-field
          v-model="formData.permitCode"
          placeholder="许可证编码"
          right-icon="search"
          @click-right-icon="onSearch"
        />
      </div>
    </van-sticky>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多数据了"
      @load="getBlowoffList"
    >
      <van-cell-group v-for="(item, index) in blowoffList" :key="index">
        <van-cell :label="item.url" is-link @click="onCellClick(item)">
          <span class="cell-title" slot="title">{{ item.permitCode }}</span>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      enterpriseId: localStorage.getItem("enterpriseId"),
      formData: {
        permitCode: "",
      },
      loading: false, // 上拉加载loading
      finished: false, // 上拉加载完结loading
      current: 1, // 页码
      pagesize: 10, // 单页条数
      blowoffList: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    // 获取许可证列表
    getBlowoffList() {
      const data = {
        index: this.current,
        size: this.pagesize,
        permitCode: this.formData.permitCode,
        enterpriseId: this.enterpriseId,
      };
      this.$api.setting
        .getPermit(data)
        .then((res) => {
          if (res.data.state == 0) {
            this.blowoffList.push(...res.data.data.records);
            if (this.current >= res.data.data.pages) {
              this.finished = true;
            } else {
              this.current++;
            }
          }
        })
        .catch((err) => {
          this.finished = true;
          throw err;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 搜索
    onSearch() {
      this.current = 1;
      this.blowoffList = [];
      this.getBlowoffList();
    },
    // 单元格点击
    onCellClick(item) {
      this.$router.push({
        path: "setting-blowoff-edit",
        query: {
          operate: "edit",
          id: item.id,
        },
      });
    },
    // 新增
    addList() {
      this.$router.push({
        path: "setting-blowoff-edit",
        query: {
          operate: "add",
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
